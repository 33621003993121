import React from "react"
import Layout from "../../components/layout-tips"

export default () => (
  <Layout title="Know Your Student">
    <h2>Know Your Student</h2>
    <div className="clear-block">
      <div id="node-12" className="node">


        <div className="content clear-block">
          <p><img src="/images/203-ryan-250x333.jpg" className="float-right" width="250"
                  height="333" alt=""/> Getting to know your student is of utmost importance! Even if you are a parent teaching
            your young child, and you think you know your child well, there are factors in any individual’s life that
            affects how they learn and their attitude toward learning. As a parent, you live with these factors in your
            everyday activities with your child and family, possibly not realizing how they might affect each
            individual’s ability to learn. So regardless of how you are related to your music student give a little
            thought to some of these important factors as you work through your lesson time together.</p>
          <ol>
            <li>How old is the child you are working with and how mature are they for their age? There are many factors
              that affect the maturity of a child, but that maturity will definitely be a factor in your teaching
              experience. If the child is immature for their age, do not baby them, but their lesson and practice time
              will need to be simpler than a more mature child of the same age.
            </li>
            <li>Is there a learning disability you need to take into consideration? If you sense that there could
              possibly be some problem in this area and you are a parent starting your own child in music, think about
              this, or if you are a teacher try talking to the parent. Some parents don’t want to think their child has
              a learning problem or disability, but with thoughtful and carefully placed questions, it is likely you
              will gain the knowledge you need, and this information will make you job easier and smoother.
            </li>
            <li>Is there a learning disability you need to take into consideration? If you sense that there could
              possibly be some problem in this area and you are a parent starting your own child in music, think about
              this, or if you are a teacher try talking to the parent. Some parents don’t want to think their child has
              a learning problem or disability, but with thoughtful and carefully placed questions, it is likely you
              will gain the knowledge you need, and this information will make you job easier and smoother.
            </li>

            <li>Are there underlining stresses at home that can affect the child? A child is very sensitive to stress in
              the home and even in their classroom at school. They will unconsciously react to it, and become stressed
              and resistant to learning. You will usually easily pick this up, so be gentle, slow and understanding
              during these times. The child might be rebellious or uncooperative, remember that getting frustrated with
              the child is not going to help anything. Be gentle, but also be firm as you work with them.
            </li>
            <li>What is the birth order of the child? There has been a lot of research on this topic and it shows that
              birth order does affect a child’s performance. For you as their music teacher, make each child feel
              important and unique. It helps so much regardless of their family position.
            </li>
            <li>Is the student a boy or girl? It does make a difference. In general, girls are more sensitive.
              Correcting them must be done with a gentler voice, harshness can easily bring tears. Boys, on the other
              hand, think they are tough and can take your firm, no nonsense voice. I have never had a boy cry, because
              of correction, but they can become belligerent.
            </li>
            <li>Is the child tired from school and/or other activities? Especially with older students, studies can
              interfere with practice time. Simply have the child practice with them and assign less for the next week.
              Sometimes, being tired is an excuse, so if it goes on for more than a few weeks, check with the parents to
              see if there is a reason. If they know of no reason the child should be tired, be firm and stop accepting
              their excuses.
            </li>
            <li>Has the child had a bad day with friends or school work? Bad days happen to all of us, so if a child
              comes to his/her lesson with a sad face or chip on their shoulder, try to get them to talk about what is
              going on. Often times getting it off their “chests” will help them relax and a good lesson will follow.
            </li>
            <li>What is the parents’ attitude toward education? If you are the parent teaching your child, please take a
              moment to think about this factor. It does influence how your child learns. Over the years I have had
              parents who are very structured about their child’s education, and parents who want their child to choose
              their own direction of what they want to learn. Frankly, the latter can be more difficult to work with,
              but knowing what the parents expect helps you to structure your lessons so both the parents and child are
              satisfied.
            </li>
          </ol>
          <h3>Testimonial</h3>
          <p><i>"Thank you for the quick delivery. I needed it for my daughter's birthday party :0) We have really
            enjoyed the books so far. I taught piano when I was in high school. My youngest student was a mature and
            intelligent kindergartener, but even she struggled with the intro piano. My daughter is thrilled."<br/>
            - Molly</i></p>
        </div>

        <div className="clear-block">
          <div className="meta">
          </div>

        </div>

      </div>
    </div>
  </Layout>
)
